<template>
  <section>
    <div class="w-100 bg-white shadow-sm rounded-lg p-4 mt-3">
      <h5 class="font-weight-bold">
        {{ $t(`my-account.views.account-settings.components.business-address.title`) }}
      </h5>
      <div v-if="formattedAddress">
        <div class="d-flex my-3">
          <div class="d-flex align-items-center mr-3">
            <hs-icon size="30" variant="light" icon="map-marker-alt" class="text-purple mr-3" />
          </div>
          <div class="address-container">
            <p class="mb-1">{{ formattedAddress }}</p>
            <span v-if="formattedCep" class="font-size-sm"
              ><strong class="text-grey">CEP:</strong> {{ formattedCep }}</span
            >
          </div>
        </div>
        <span class="font-size-sm">
          {{ $t(`my-account.views.account-settings.components.business-address.informative`) }}
        </span>
      </div>
      <div v-else>
        <p class="no-address my-3">
          {{ $t(`my-account.views.account-settings.components.business-address.no-address`) }}
        </p>
        <MButton
          class="w-100"
          icon="plus-circle"
          :label="$t(`my-account.views.account-settings.components.business-address.btn-label`)"
          variant="primary-outline"
          @click="emitEvent()"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  name: 'BusinessAddress',
  props: {
    address: {
      type: Object,
    },
  },
  components: {
    MButton,
  },
  computed: {
    formattedAddress() {
      const checkAddress = this.address ? Object.values(this.address).some(value => !!value) : false;
      return checkAddress
        ? `${this.address.street}, ${this.address.street_number} ${this.address.complementary}. ${
            this.address.neighborhood
          }, ${this.address.city}/${this.address.state}`
        : '';
    },
    formattedCep() {
      return this.address.zipcode;
    },
  },
  methods: {
    emitEvent() {
      this.$root.$emit('showCompleteModalOnlyAddress', { showOnlyAddress: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-address,
.address-container {
  font-size: 16px;
  color: #525252;
}
</style>
